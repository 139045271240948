<template>
  <div >
    <v-app class="custom-height" v-if="$router.currentRoute.path !== '/popout'">
    <v-navigation-drawer mobile-breakpoint="1024" :class="$store.state.isMobileView ? 'overflow-y-hidden' : 'overflow-y-auto'" class="customize" v-model="drawer" app width="64">
      <div width="64">
        <div @click="navigatePage('/home')" class="cursor d-flex justify-center align-center mx-2" style="height: 56px; border-bottom: solid 1px #D6D6D6">
 <img width="24px" src="../assets/images/sidebarLogo.png" alt="" id="side_logo">
        </div>

        <v-tabs class="sidebar mt-3" vertical v-model="selectedItem" :color="$store.state.buttonThemeColor" id="side_tabs">
          <v-tab class="sidebarTab" v-for="(item, i) in menuList" @click="changeRoute(item)" :key="i" :id="`${i}_tab_${item.text}`">
            <div class="menu">
               <customIcon :name="item.svg.toLowerCase()" :width="'24'" :height="'24'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode"/>
              <div class="fsize11 primaryColor text-overflow">{{ item.text == 'marketwatch' ? 'MW List' : item.text }}</div>
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </v-navigation-drawer>

    <v-app-bar height="56" app flat class="appheader border-bottom-dark pa-0">
      <v-app-bar-nav-icon v-if="$store.state.isMobileView" @click="drawer = !drawer" class="ma-0"> <customIcon  :name="'drawer'" :width="'24'" :height="'24'" :color="'#6F6F6F'"/></v-app-bar-nav-icon>
      <div class="row ma-0  pa-0 " :class="$store.state.isMobileView && !$store.state.isLapView ? 'justify-end' : 'justify-space-between'">
        <div :widthChangeByView="!$store.state.isMobileView && !$store.state.isLapView ? '375' : !$store.state.isMobileView && $store.state.isLapView ? '400' : '0'" id="header_top_left">
          <div class="row ma-0  d-flex justify-space-around text-uppercase" :class="$store.state.isMobileView && !$store.state.isLapView ? '' : ''" v-if="!$store.state.isMobileView">
            <div class="text-center fsize12 px-3 width-20 primaryColor hovershow" id="niftyFifty">
              <div class="w-100" id="niftyFifty_name">{{niftyFiftyArray.name}}</div>
              <div class="font-weight-600" id="niftyFifty_value">
                <span :class="parseFloat(niftyFiftyArray.change) >= 0 ? 'positiveColor' : 'negativeColor'" id="niftyFifty_lp">{{niftyFiftyArray.value}}</span>
                <span :class="parseFloat(niftyFiftyArray.change) >= 0 ? 'positiveColor' : 'negativeColor'" class="ml-1" id="niftyFifty_per_change">{{niftyFiftyArray.change ? `(${niftyFiftyArray.change}%)` : ''}}</span>
              </div>
                  <label class="hoverBtn indexChart" :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'" id="niftyFifty_icon" style="top: 15px;position: absolute; display: none" @click="callTrandingViewChart(niftyFiftyArray,'header')">
                      <customIcon :name="'chart'" :width="'24'" :height="'24'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode" />
                      </label>
            </div>
            <div class="text-center fsize12 border-lft px-3 width-20 primaryColor hovershow" id="niftyBank">
              <div class="w-100" id="niftyBank_name">{{niftyBankArray.name}}</div>
              <div class="font-weight-600" id="niftyBank_value">
                <span :class="parseFloat(niftyBankArray.change) >= 0 ? 'positiveColor' : 'negativeColor'" id="niftyBank_lp">{{niftyBankArray.value}}</span>
                <span :class="parseFloat(niftyBankArray.change) >= 0 ? 'positiveColor' : 'negativeColor'" id="niftyBank_per_change" class="ml-1">{{niftyBankArray.change ? `(${niftyBankArray.change}%)` : ''}}</span>
              </div>
                <label class="hoverBtn indexChart" id="niftyBank_icon" :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'" style="top: 15px;position: absolute; display: none" @click="callTrandingViewChart(niftyBankArray,'header')">
                      <customIcon :name="'chart'" :width="'24'" :height="'24'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode" />
                      </label>
            </div>
            <div class="text-center fsize12 border-lft px-3 width-20 primaryColor hovershow" id="sensex">
              <div class="w-100" id="sensex_name">{{senSexArray.name}}</div>
              <div class="font-weight-600" id="sensex_value">
                <span id="sensex_lp" :class="parseFloat(senSexArray.change) >= 0 ? 'positiveColor' : 'negativeColor'">{{senSexArray.value}}</span>
                <span id="sensex_per_change" :class="parseFloat(senSexArray.change) >= 0 ? 'positiveColor' : 'negativeColor'" class="ml-1">{{senSexArray.change ? `(${senSexArray.change}%)`: ''}}</span>
              </div>
                <label class="hoverBtn indexChart" id="sensex_icon" :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'" style="top: 15px;position: absolute; display: none" @click="callTrandingViewChart(senSexArray,'header')">
                      <customIcon :name="'chart'" :width="'24'" :height="'24'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode" />
                      </label>
            
            </div>
            <div class="text-center fsize12 border-lft px-3 width-20 primaryColor hovershow" id="nifty_one_hundered">
              <div class="w-100" id="nifty_one_hundered_name">{{niftyOneHunderedArray.name}}</div>
              <div class="font-weight-600" id="nifty_one_hundered_value">
                <span id="nifty_one_hundered_lp" :class="parseFloat(niftyOneHunderedArray.change) >= 0 ? 'positiveColor' : 'negativeColor'">{{niftyOneHunderedArray.value}}</span>
                <span id="nifty_one_hundered_per_change" :class="parseFloat(niftyOneHunderedArray.change) >= 0 ? 'positiveColor' : 'negativeColor'" class="ml-1">{{niftyOneHunderedArray.change ? `(${niftyOneHunderedArray.change}%)` : ''}}</span>
              </div>
               <label class="hoverBtn indexChart" id="nifty_one_hundered_icon" :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'" style="top: 15px;position: absolute; display: none" @click="callTrandingViewChart(niftyOneHunderedArray,'header')">
                      <customIcon :name="'chart'" :width="'24'" :height="'24'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode" />
                      </label>
                    
            </div>
            <div class="text-center fsize12 border-lft px-3 width-20 primaryColor hovershow" id="nifty_two_hundered">
              <div class="w-100" id="nifty_two_hundered_name">{{niftytwoHunderedArray.name}}</div>
              <div class="font-weight-600" id="nifty_two_hundered_value">
                <span id="nifty_two_hundered_lp" :class="parseFloat(niftytwoHunderedArray.change) >= 0 ? 'positiveColor' : 'negativeColor'">{{niftytwoHunderedArray.value}}</span>
                <span id="nifty_two_hundered_per_change" :class="parseFloat(niftytwoHunderedArray.change) >= 0 ? 'positiveColor' : 'negativeColor'" class="ml-1">{{niftytwoHunderedArray.change ? `(${niftytwoHunderedArray.change}%)` : ''}}</span>
              </div>
 <label class="hoverBtn indexChart" id="nifty_two_hundered_icon" :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'" style="top: 15px;position: absolute; display: none" @click="callTrandingViewChart(niftytwoHunderedArray,'header')">
                      <customIcon :name="'chart'" :width="'24'" :height="'24'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode" />
                      </label>
            </div>
            
          </div>
        </div>
        <div class="pr-6 d-flex align-center" id="header_top_right">
          <div class="d-flex flex-column justify-end text-end">
            <v-menu max-width="190" right bottom :offset-y="true" transition="slide-y-transition" :position-y="300" rounded>
              <template v-slot:activator="{ on, attrs }">
                <div text v-bind="attrs" v-on="on" class="fsize12">
                  <div class="primaryColor" id="active_acc_name">{{ getUserSessionDto.accountName}}</div>
                  <div class="secondaryColor" id="active_Id">{{ getUserId}}</div>
                </div>
              </template>
              <v-list class="py-0">
                <v-list-item class="body-2 header_menu" @click="navigatePage('/settings')" id="settings_list_item">
                  <v-list-item-icon>
                    <customIcon :name="'settings'" :width="'20'" :height="'20'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconGrayColor" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <span class="ml-2">Settings</span>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="body-2 header_menu cursor"  @click="navigateVendor('dashboard')" id="dashboard_list_item">
                  <v-list-item-icon>
                    <customIcon :name="'link'" :width="'20'" :height="'20'" :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconGrayColor" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <span class="ml-2">Dashboard</span>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="body-2 header_menu " @click="logoutDialog = true"  id="logout_list_item">
                  <v-list-item-icon>
                     <customIcon :name="'logout'" :width="'20'" :height="'20'" :color="$store.state.amogaColor" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <a class="ml-2">Logout</a>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

        </div>
      </div>
    </v-app-bar>

    <v-main class="">
      <div  :widthChangeByView="!$store.state.isMobileView && !$store.state.isLapView ? '375' : !$store.state.isMobileView && $store.state.isLapView ? '400' : '0'" class="wrapper-router overflow-y-auto content-height">
        <router-view></router-view>
      </div>
      <mkWatch class="basket-wrapper" v-if="!$store.state.isMobileView" />
    </v-main>
    <div :class="showOrderWindow ? 'd-block' : 'd-none'">
      <orderWindow  />
    </div>
    
    <authorizeDialog />
    <brokageDialog />
    <alertDialog />
    <deleteAlertDialogVue />
    <risk_disclosure v-if="$store.state.isRiskDialog"/>
    <v-dialog v-model="logoutDialog" width="400px">
      <v-card>
        <div class="pb-2 px-4 pt-4">
          <!-- <span class="fsize20 orange--text mr-2">&#9888;</span>  -->
          Are you sure want to logout?
          <!-- <span>Are you sure want to logout?</span> -->
          <button type="button" @click="logoutDialog = false" id="logout_icon" class="fsize21 float-right" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="row ma-0 pa-4 secondaryColor" id="logout_info">
           <span class="fsize13"> Please be advised, all sessions, including API and algo based applications/sessions, will be logged out.</span>
        </div>
        <div class="px-4 pt-2 pb-4 d-flex align-end justify-end">
          <v-btn depressed :color="$store.state.buttonThemeColor" :loading="logoutLoader" :disabled="logoutLoader" @click="$store.dispatch('header/logout');$store.commit('orderWindow/setOrderWindow',false);" class="flat text-capitalize fsize14 white--text " id="logout_confirm_btn">Confirm</v-btn>
          <v-btn depressed color="#fff" outlined @click="logoutDialog = false" class="flat  text-capitalize fsize14 ml-3" :class="$vuetify.theme.dark ? '' : 'black--text'" id="logout_cancel_btn">Cancel</v-btn>

        </div>
      </v-card>
    </v-dialog>
    
  </v-app>
  <div v-else class="h-100">
    <router-view></router-view>
  </div>
  </div>
</template>

<script>
import mkWatch from "../views/marketWatch.vue";
import authorizeDialog from "../components/authorizeDialog.vue"
import orderWindow from "../components/orderWindow.vue";
import { mapGetters, mapState } from "vuex";
import customIcon from '../components/customIcon.vue'
import commonFunc from "../mixins/commonFunctions";
import window from "../mixins/orderWindow"
import formula from "../store/Services/formula"
import brokageDialog from "../components/brokageDialog.vue"
import alertDialog from "../components/alertDialog.vue"
import deleteAlertDialogVue from "../components/deleteAlertDialog.vue";
import risk_disclosure from "../components/risk_disclosure.vue";
export default {
  mixins:[commonFunc, window],
  data: () => ({
    drawer: null,
    showMenu: false,
    logoutDialog: false,
  }),
  computed: {
    ...mapGetters("authentication", [
      "getUserId",
      "getUserSession",
      "getUserSessionDto",
    ]),
    ...mapGetters("header", ['getIndex']),

    ...mapState("header", [
      "niftyFiftyArray",
      "niftyOneHunderedArray",
      "niftyBankArray",
      "niftytwoHunderedArray",
      "senSexArray",
      "logoutLoader",
      "menuList",
      "tabSelection"
    ]),
    ...mapState("authentication", ["userId"]),
    ...mapState("orderWindow", ["showOrderWindow", "windowLoading"]),
     ...mapState("holdings", [
      "holdingList"]),
    selectedItem:{
        get() { return this.tabSelection},
        set(newValue) {
            this.$store.dispatch('header/setTabSelection' , newValue)
        }
    },
    isMobileView () {
      return this.$store.state.isMobileView
    }
  },
 async  beforeCreate(){
  localStorage.getItem('isRiskDialog') && localStorage.getItem('isRiskDialog') != "undefined" && JSON.parse(localStorage.getItem('isRiskDialog')) ? this.$store.dispatch('getDialogContent') : ''
  await this.$store.dispatch('marketWatch/getwatchList') 
  this.$store.commit('authentication/setIsResend' , false)
 this.$router.currentRoute.path == "/home" || this.$router.currentRoute.path == "/holdings" ? '' : this.$store.dispatch("holdings/getHoldings");
  this.$store.state.windowWidth <=1023 ? this.$store.dispatch('header/setMenuList',true) : this.$store.dispatch('header/setMenuList',false)
  this.$store.dispatch('wsConnection/inValidateSession')
   await this.$store.dispatch("wsConnectionTr/connect");
  },
  async created() {
    await this.$store.dispatch("header/getIndex", "NSE");
    await this.$store.dispatch("header/getIndex", "BSE");
  },
  components: {
    mkWatch,
    orderWindow,
    customIcon,
    authorizeDialog,
    brokageDialog,
    alertDialog,
    deleteAlertDialogVue,risk_disclosure
  },
  methods: {
    navigateVendor(vendor){
      if(vendor == 'dashboard'){
        this.$store.commit('setValidateAuthCode', this.$store.state.dashBoardAuthCode)
        this.$store.dispatch('authentication/validateVendor', {appcode: this.$store.state.dashBoardAuthCode})
      }
    },
    changeRoute(val) {
      this.$router.push("/" + val.text).catch(() => {});
    },

    navigatePage(route) {
     this.selectedItem = route == '/home' ? 0 : this.menuList.length - 1
      this.$router.push(route.toString().toLowerCase()).catch(() => {});
    },
    listenMwTicks(event) {
      var tickerData = event.detail.storage;
      this.listenIndexTicks(tickerData)
      this.listenHoldTicker(tickerData)
    },
     listenIndexTicks(tickerData){
      if(tickerData['1']){
        let sensex = tickerData['1']
          this.senSexArray['value'] = sensex?.lp ? sensex.lp : this.senSexArray['value']
          this.senSexArray['change'] = sensex?.pc ? sensex.pc : this.senSexArray['change']
          this.senSexArray['o'] = sensex?.o ? sensex.o : this.senSexArray['o']
          this.senSexArray['h'] = sensex?.h ? sensex.h : this.senSexArray['h']
          this.senSexArray['l'] = sensex?.l ? sensex.l : this.senSexArray['l']
        }
      if(tickerData['26033']){
        let indiaVix = tickerData['26033']
          this.niftytwoHunderedArray['value'] = indiaVix?.lp ? indiaVix.lp : this.niftytwoHunderedArray['value']
          this.niftytwoHunderedArray['change'] = indiaVix?.pc ? indiaVix.pc : this.niftytwoHunderedArray['change']
          this.niftytwoHunderedArray['o'] = indiaVix?.o ? indiaVix.o :  this.niftytwoHunderedArray['o']
          this.niftytwoHunderedArray['h'] = indiaVix?.h ? indiaVix.h :  this.niftytwoHunderedArray['h']
          this.niftytwoHunderedArray['l'] = indiaVix?.l ? indiaVix.l :  this.niftytwoHunderedArray['l']
        }
      if(tickerData['26009']){
        let niftyBank = tickerData['26009']
          this.niftyBankArray['value'] = niftyBank?.lp ? niftyBank.lp : this.niftyBankArray['value']
          this.niftyBankArray['change'] = niftyBank?.pc ? niftyBank.pc : this.niftyBankArray['change']
          this.niftyBankArray['o'] = niftyBank?.o ? niftyBank.o : this.niftyBankArray['o']
          this.niftyBankArray['h'] = niftyBank?.h ? niftyBank.h : this.niftyBankArray['h']
          this.niftyBankArray['l'] = niftyBank?.l ? niftyBank.l : this.niftyBankArray['l']
        }
      if(tickerData['26000']){
        let niftyFifty = tickerData['26000']
          this.niftyFiftyArray['value'] = niftyFifty?.lp ? niftyFifty.lp : this.niftyFiftyArray['value']
          this.niftyFiftyArray['change'] = niftyFifty?.pc ? niftyFifty.pc : this.niftyFiftyArray['change']
          this.niftyFiftyArray['o'] = niftyFifty?.o ? niftyFifty.o : this.niftyFiftyArray['o']
          this.niftyFiftyArray['h'] = niftyFifty?.h ? niftyFifty.h : this.niftyFiftyArray['h']
          this.niftyFiftyArray['l'] = niftyFifty?.l ? niftyFifty.l : this.niftyFiftyArray['l']
        }
        if(tickerData['26012']){
          let niftyFin = tickerData['26012']
          this.niftyOneHunderedArray['value'] = niftyFin?.lp ? niftyFin.lp : this.niftyOneHunderedArray['value']
          this.niftyOneHunderedArray['change'] = niftyFin?.pc ? niftyFin.pc : this.niftyOneHunderedArray['change']
          this.niftyOneHunderedArray['o'] = niftyFin?.o ? niftyFin.o : this.niftyOneHunderedArray['o']
          this.niftyOneHunderedArray['h'] = niftyFin?.h ? niftyFin.h : this.niftyOneHunderedArray['h']
          this.niftyOneHunderedArray['l'] = niftyFin?.l ? niftyFin.l : this.niftyOneHunderedArray['l']
        }
    },
    listenHoldTicker(tickerData){
      let token = this.$store.state.wsConnection.validToken
       var selectedArray = this.holdingList.filter((el) => {
        return el.token == token;
      });
        if(token && selectedArray.length > 0){
          for(let element of selectedArray){
          var data = tickerData[element.token]
                    element.l = data?.l ? data.l : element.l
                    element.h = data?.h ? data.h : element.h
                    element.c = data?.c ? data.c : element.c
                    element.volume = data?.v ? data.v : element.volume 
                    element.ltp = data?.lp ? data.lp : element.ltp
            let index = this.holdingList.indexOf(element)
            this.holdingList[index] = element
          formula.holdingsProfitLoss()
          }
        }
        
    }
  },
  mounted() {
    this.selectedItem = this.menuList.findIndex(
          (std) => std.text === this.$router.currentRoute.path.replace("/", "")
        );
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },

};
</script>

<style scoped>
.wrapper-router {
  float: left;
  width: 100%;
  min-height: 1px;
  transition: all 0.1s;
}
.sidebar {
  width: 64px !important;
}
.v-navigation-drawer__content {
  overflow: clip !important;
}
.border-lft {
  border-left: 1px solid #ededed;
}
::v-deep.v-menu__content {
  top: 60px !important;
  right: 18px !important;
  left: auto !important;
}
/* .v-icon.v-icon{
  font-size: 20px !important;
} */
::v-deep.v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0px !important;
}

.hovershow:hover .indexChart{
  display: block !important;
}

.sidebar .v-tab {
  min-width: 64px !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  color: #282828 !important;
  padding: 8px 0px !important;
  letter-spacing: normal !important;
}
.sidebar .v-tab--active {
  color: #0065be !important;
}
.sidebar .v-tab--active {
filter: invert(68%) sepia(49%) saturate(6379%) hue-rotate(183deg) brightness(93%) contrast(104%) !important;
}
.sidebar .v-tab:hover {
  filter: invert(68%) sepia(49%) saturate(6379%) hue-rotate(183deg) brightness(93%) contrast(104%) !important;
}
.theme--light .darkThemeImg {
  display: contents !important;
}
.theme--dark .sidebar .v-tab .unactive_img {
  display: contents !important;
}
.theme--dark .sidebar .v-tab--active .unactive_img {
  display: inline-block !important;
}
.theme--dark .sidebar .v-tab {
  height: 56px !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  color: #ffffff !important;
  padding: 8px 0px !important;
  letter-spacing: normal !important;
}
.theme--light .sidebar .v-tab {
  height: 56px !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  color: #282828 !important;
  padding: 8px 0px !important;
  letter-spacing: normal !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
  background: #363636 !important;
}
.theme--dark.v-navigation-drawer {
  background-color: #1e1e1e !important;
}
.theme--dark.v-app-bar.v-toolbar {
  background-color: #1e1e1e !important;
}
[widthChangeByView="400"] {
  width: calc(100% - 400px) !important;
}
[widthChangeByView="375"] {
  width: calc(100% - 375px) !important;
}
[widthChangeByView="0"] {
  width: calc(100% - 0px) !important;
}
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 24px !important;
}
.text-overflow{
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width:60px
}
</style>